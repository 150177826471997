import $ from 'jquery';

export default defineNuxtPlugin(async () => {
    if (!import.meta.client) {
        return;
    }

    await import('slick-carousel');

    const $carousel = $('#slick-carousel');
    if (!$carousel.length) {
        return;
    }

    const sliderOptions = {
        slidesToScroll: 1,
        slidesToShow: 3,
        autoplay: true,
        arrows: true,
        prevArrow: $('#carousel-arrow-prev'),
        nextArrow: $('#carousel-arrow-next'),
        responsive: [
            {
                breakpoint: 1199.98,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 991.98,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 575.98,
                settings: {
                    slidesToShow: 1,
                }
            },

        ]
    };

    let timeout = setTimeout(() => {
        $carousel.slick(sliderOptions);
        clearTimeout(timeout);
    }, 1000);
});

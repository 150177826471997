export default defineNuxtPlugin(() => {
    if (!import.meta.client) {
        return;
    }

    const active = 'hover-item-blur-active';
    const items = document.querySelectorAll('.hover-item-blur');
    const clearBlur = () => items.forEach((item) => item.classList.remove(active));
    const activateBlur = (e) => items.forEach((item) => {
        if (!item.classList.contains(active)) {
            item.classList.add(active);
        }

        e.target.classList.remove(active);
    })

    items.forEach(item => {
        item.addEventListener('mouseenter', e => activateBlur(e));
        item.addEventListener('mouseleave', clearBlur);
    })
});

import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as index8PUABJWAo9Meta } from "/app/pages/about/contact/index.vue?macro=true";
import { default as indexTRqRpIOxLQMeta } from "/app/pages/about/index.vue?macro=true";
import { default as indexhjIl4adDkXMeta } from "/app/pages/about/our-story/index.vue?macro=true";
import { default as indexAfj5ehTX2TMeta } from "/app/pages/about/partners/index.vue?macro=true";
import { default as indexS6Yq6tbw0dMeta } from "/app/pages/blog/[slug]/index.vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as _91page_93XjZzxmj3IbMeta } from "/app/pages/blog/page/[page].vue?macro=true";
import { default as index1mEL9i1OvLMeta } from "/app/pages/blog/page/index.vue?macro=true";
import { default as indexhcEGOIOBKjMeta } from "/app/pages/blog/post/index.vue?macro=true";
import { default as index4qRYgjYBtRMeta } from "/app/pages/build-with-us/basement-design/index.vue?macro=true";
import { default as indexeBh45JAkfhMeta } from "/app/pages/build-with-us/custom-houses/index.vue?macro=true";
import { default as indexzGyVoCRGc9Meta } from "/app/pages/build-with-us/dual-occupancy-homes/index.vue?macro=true";
import { default as indexxar275BUQVMeta } from "/app/pages/build-with-us/knockdown-rebuild/index.vue?macro=true";
import { default as indexykI8gSgeuPMeta } from "/app/pages/build-with-us/multi-dwelling-houses/index.vue?macro=true";
import { default as indexmBploIUwxeMeta } from "/app/pages/contact-us/index.vue?macro=true";
import { default as indexG0A3ox5fSPMeta } from "/app/pages/custom-gallery/index.vue?macro=true";
import { default as indexUXdp1ve6iGMeta } from "/app/pages/custom-homes/index.vue?macro=true";
import { default as indexVjB7uaNBhEMeta } from "/app/pages/custom-process/index.vue?macro=true";
import { default as indexzIyEIf26X3Meta } from "/app/pages/designed-for-living/index.vue?macro=true";
import { default as indexSOXMEuMTrEMeta } from "/app/pages/discover-your-style/index.vue?macro=true";
import { default as indexUVU1KsS9tQMeta } from "/app/pages/display-homes/homeworld-box-hill/index.vue?macro=true";
import { default as indexEd66TflXDXMeta } from "/app/pages/display-homes/homeworld-oran-park/index.vue?macro=true";
import { default as indexFHMP6UJMu5Meta } from "/app/pages/display-homes/index.vue?macro=true";
import { default as indexCUPirXSCIBMeta } from "/app/pages/display-homes/selection-studio/index.vue?macro=true";
import { default as indexSfuuiyCw42Meta } from "/app/pages/double-storey-house-plans/index.vue?macro=true";
import { default as indexK7GFr9nQT9Meta } from "/app/pages/duplex-houses/index.vue?macro=true";
import { default as index4pvaAot8x4Meta } from "/app/pages/explore-the-range/index.vue?macro=true";
import { default as indexR9dFZtSlxZMeta } from "/app/pages/home-designs/[slug]/index.vue?macro=true";
import { default as index6zo5OUWAvYMeta } from "/app/pages/house-and-land-packages/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexpbK3uoJMnjMeta } from "/app/pages/james-hardie/index.vue?macro=true";
import { default as indexLkUZTZqQtlMeta } from "/app/pages/jh-styles/[slug]/index.vue?macro=true";
import { default as indexlUxjb7K1eyMeta } from "/app/pages/our-collections/august/index.vue?macro=true";
import { default as indexjD4cc5Ux45Meta } from "/app/pages/our-collections/index.vue?macro=true";
import { default as indexziOWPWspxsMeta } from "/app/pages/our-collections/lifestyle/index.vue?macro=true";
import { default as indexQmZvqx2nBGMeta } from "/app/pages/our-collections/mayfair/index.vue?macro=true";
import { default as indexpnR0nwMn4QMeta } from "/app/pages/our-homes/double-storey/index.vue?macro=true";
import { default as indexOEsJe3fczSMeta } from "/app/pages/our-homes/dual-occupancy-homes/index.vue?macro=true";
import { default as indexqaE5c7S9SpMeta } from "/app/pages/our-homes/index.vue?macro=true";
import { default as indexzB1UDSlaRrMeta } from "/app/pages/our-homes/knockdown-rebuild/index.vue?macro=true";
import { default as indexwfFcOFbHmJMeta } from "/app/pages/our-homes/multi-dwelling-houses/index.vue?macro=true";
import { default as index0LpzNszo5FMeta } from "/app/pages/our-homes/single-storey/index.vue?macro=true";
import { default as indexLG3Wtz8U6fMeta } from "/app/pages/partners/index.vue?macro=true";
import { default as indext2mji5fLq5Meta } from "/app/pages/privacy-policy/index.vue?macro=true";
import { default as index2ewsxcR5WvMeta } from "/app/pages/single-storey-house-plans/index.vue?macro=true";
import { default as indexBOCZxyK5PsMeta } from "/app/pages/where-we-build/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93ILszah2VNpMeta?.name ?? "slug",
    path: _91_46_46_46slug_93ILszah2VNpMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    alias: _91_46_46_46slug_93ILszah2VNpMeta?.alias || [],
    redirect: _91_46_46_46slug_93ILszah2VNpMeta?.redirect,
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index8PUABJWAo9Meta?.name ?? "about-contact",
    path: index8PUABJWAo9Meta?.path ?? "/about/contact",
    meta: index8PUABJWAo9Meta || {},
    alias: index8PUABJWAo9Meta?.alias || [],
    redirect: index8PUABJWAo9Meta?.redirect,
    component: () => import("/app/pages/about/contact/index.vue").then(m => m.default || m)
  },
  {
    name: indexTRqRpIOxLQMeta?.name ?? "about",
    path: indexTRqRpIOxLQMeta?.path ?? "/about",
    meta: indexTRqRpIOxLQMeta || {},
    alias: indexTRqRpIOxLQMeta?.alias || [],
    redirect: indexTRqRpIOxLQMeta?.redirect,
    component: () => import("/app/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indexhjIl4adDkXMeta?.name ?? "about-our-story",
    path: indexhjIl4adDkXMeta?.path ?? "/about/our-story",
    meta: indexhjIl4adDkXMeta || {},
    alias: indexhjIl4adDkXMeta?.alias || [],
    redirect: indexhjIl4adDkXMeta?.redirect,
    component: () => import("/app/pages/about/our-story/index.vue").then(m => m.default || m)
  },
  {
    name: indexAfj5ehTX2TMeta?.name ?? "about-partners",
    path: indexAfj5ehTX2TMeta?.path ?? "/about/partners",
    meta: indexAfj5ehTX2TMeta || {},
    alias: indexAfj5ehTX2TMeta?.alias || [],
    redirect: indexAfj5ehTX2TMeta?.redirect,
    component: () => import("/app/pages/about/partners/index.vue").then(m => m.default || m)
  },
  {
    name: indexS6Yq6tbw0dMeta?.name ?? "blog-slug",
    path: indexS6Yq6tbw0dMeta?.path ?? "/blog/:slug()",
    meta: indexS6Yq6tbw0dMeta || {},
    alias: indexS6Yq6tbw0dMeta?.alias || [],
    redirect: indexS6Yq6tbw0dMeta?.redirect,
    component: () => import("/app/pages/blog/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgWaMlevJ7RMeta?.name ?? "blog",
    path: indexgWaMlevJ7RMeta?.path ?? "/blog",
    meta: indexgWaMlevJ7RMeta || {},
    alias: indexgWaMlevJ7RMeta?.alias || [],
    redirect: indexgWaMlevJ7RMeta?.redirect,
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93XjZzxmj3IbMeta?.name ?? "blog-page-page",
    path: _91page_93XjZzxmj3IbMeta?.path ?? "/blog/page/:page()",
    meta: _91page_93XjZzxmj3IbMeta || {},
    alias: _91page_93XjZzxmj3IbMeta?.alias || [],
    redirect: _91page_93XjZzxmj3IbMeta?.redirect,
    component: () => import("/app/pages/blog/page/[page].vue").then(m => m.default || m)
  },
  {
    name: index1mEL9i1OvLMeta?.name ?? "blog-page",
    path: index1mEL9i1OvLMeta?.path ?? "/blog/page",
    meta: index1mEL9i1OvLMeta || {},
    alias: index1mEL9i1OvLMeta?.alias || [],
    redirect: index1mEL9i1OvLMeta?.redirect,
    component: () => import("/app/pages/blog/page/index.vue").then(m => m.default || m)
  },
  {
    name: indexhcEGOIOBKjMeta?.name ?? "blog-post",
    path: indexhcEGOIOBKjMeta?.path ?? "/blog/post",
    meta: indexhcEGOIOBKjMeta || {},
    alias: indexhcEGOIOBKjMeta?.alias || [],
    redirect: indexhcEGOIOBKjMeta?.redirect,
    component: () => import("/app/pages/blog/post/index.vue").then(m => m.default || m)
  },
  {
    name: index4qRYgjYBtRMeta?.name ?? "build-with-us-basement-design",
    path: index4qRYgjYBtRMeta?.path ?? "/build-with-us/basement-design",
    meta: index4qRYgjYBtRMeta || {},
    alias: index4qRYgjYBtRMeta?.alias || [],
    redirect: index4qRYgjYBtRMeta?.redirect,
    component: () => import("/app/pages/build-with-us/basement-design/index.vue").then(m => m.default || m)
  },
  {
    name: indexeBh45JAkfhMeta?.name ?? "build-with-us-custom-houses",
    path: indexeBh45JAkfhMeta?.path ?? "/build-with-us/custom-houses",
    meta: indexeBh45JAkfhMeta || {},
    alias: indexeBh45JAkfhMeta?.alias || [],
    redirect: indexeBh45JAkfhMeta?.redirect,
    component: () => import("/app/pages/build-with-us/custom-houses/index.vue").then(m => m.default || m)
  },
  {
    name: indexzGyVoCRGc9Meta?.name ?? "build-with-us-dual-occupancy-homes",
    path: indexzGyVoCRGc9Meta?.path ?? "/build-with-us/dual-occupancy-homes",
    meta: indexzGyVoCRGc9Meta || {},
    alias: indexzGyVoCRGc9Meta?.alias || [],
    redirect: indexzGyVoCRGc9Meta?.redirect,
    component: () => import("/app/pages/build-with-us/dual-occupancy-homes/index.vue").then(m => m.default || m)
  },
  {
    name: indexxar275BUQVMeta?.name ?? "build-with-us-knockdown-rebuild",
    path: indexxar275BUQVMeta?.path ?? "/build-with-us/knockdown-rebuild",
    meta: indexxar275BUQVMeta || {},
    alias: indexxar275BUQVMeta?.alias || [],
    redirect: indexxar275BUQVMeta?.redirect,
    component: () => import("/app/pages/build-with-us/knockdown-rebuild/index.vue").then(m => m.default || m)
  },
  {
    name: indexykI8gSgeuPMeta?.name ?? "build-with-us-multi-dwelling-houses",
    path: indexykI8gSgeuPMeta?.path ?? "/build-with-us/multi-dwelling-houses",
    meta: indexykI8gSgeuPMeta || {},
    alias: indexykI8gSgeuPMeta?.alias || [],
    redirect: indexykI8gSgeuPMeta?.redirect,
    component: () => import("/app/pages/build-with-us/multi-dwelling-houses/index.vue").then(m => m.default || m)
  },
  {
    name: indexmBploIUwxeMeta?.name ?? "contact-us",
    path: indexmBploIUwxeMeta?.path ?? "/contact-us",
    meta: indexmBploIUwxeMeta || {},
    alias: indexmBploIUwxeMeta?.alias || [],
    redirect: indexmBploIUwxeMeta?.redirect,
    component: () => import("/app/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexG0A3ox5fSPMeta?.name ?? "custom-gallery",
    path: indexG0A3ox5fSPMeta?.path ?? "/custom-gallery",
    meta: indexG0A3ox5fSPMeta || {},
    alias: indexG0A3ox5fSPMeta?.alias || [],
    redirect: indexG0A3ox5fSPMeta?.redirect,
    component: () => import("/app/pages/custom-gallery/index.vue").then(m => m.default || m)
  },
  {
    name: indexUXdp1ve6iGMeta?.name ?? "custom-homes",
    path: indexUXdp1ve6iGMeta?.path ?? "/custom-homes",
    meta: indexUXdp1ve6iGMeta || {},
    alias: indexUXdp1ve6iGMeta?.alias || [],
    redirect: indexUXdp1ve6iGMeta?.redirect,
    component: () => import("/app/pages/custom-homes/index.vue").then(m => m.default || m)
  },
  {
    name: indexVjB7uaNBhEMeta?.name ?? "custom-process",
    path: indexVjB7uaNBhEMeta?.path ?? "/custom-process",
    meta: indexVjB7uaNBhEMeta || {},
    alias: indexVjB7uaNBhEMeta?.alias || [],
    redirect: indexVjB7uaNBhEMeta?.redirect,
    component: () => import("/app/pages/custom-process/index.vue").then(m => m.default || m)
  },
  {
    name: indexzIyEIf26X3Meta?.name ?? "designed-for-living",
    path: indexzIyEIf26X3Meta?.path ?? "/designed-for-living",
    meta: indexzIyEIf26X3Meta || {},
    alias: indexzIyEIf26X3Meta?.alias || [],
    redirect: indexzIyEIf26X3Meta?.redirect,
    component: () => import("/app/pages/designed-for-living/index.vue").then(m => m.default || m)
  },
  {
    name: indexSOXMEuMTrEMeta?.name ?? "discover-your-style",
    path: indexSOXMEuMTrEMeta?.path ?? "/discover-your-style",
    meta: indexSOXMEuMTrEMeta || {},
    alias: indexSOXMEuMTrEMeta?.alias || [],
    redirect: indexSOXMEuMTrEMeta?.redirect,
    component: () => import("/app/pages/discover-your-style/index.vue").then(m => m.default || m)
  },
  {
    name: indexUVU1KsS9tQMeta?.name ?? "display-homes-homeworld-box-hill",
    path: indexUVU1KsS9tQMeta?.path ?? "/display-homes/homeworld-box-hill",
    meta: indexUVU1KsS9tQMeta || {},
    alias: indexUVU1KsS9tQMeta?.alias || [],
    redirect: indexUVU1KsS9tQMeta?.redirect,
    component: () => import("/app/pages/display-homes/homeworld-box-hill/index.vue").then(m => m.default || m)
  },
  {
    name: indexEd66TflXDXMeta?.name ?? "display-homes-homeworld-oran-park",
    path: indexEd66TflXDXMeta?.path ?? "/display-homes/homeworld-oran-park",
    meta: indexEd66TflXDXMeta || {},
    alias: indexEd66TflXDXMeta?.alias || [],
    redirect: indexEd66TflXDXMeta?.redirect,
    component: () => import("/app/pages/display-homes/homeworld-oran-park/index.vue").then(m => m.default || m)
  },
  {
    name: indexFHMP6UJMu5Meta?.name ?? "display-homes",
    path: indexFHMP6UJMu5Meta?.path ?? "/display-homes",
    meta: indexFHMP6UJMu5Meta || {},
    alias: indexFHMP6UJMu5Meta?.alias || [],
    redirect: indexFHMP6UJMu5Meta?.redirect,
    component: () => import("/app/pages/display-homes/index.vue").then(m => m.default || m)
  },
  {
    name: indexCUPirXSCIBMeta?.name ?? "display-homes-selection-studio",
    path: indexCUPirXSCIBMeta?.path ?? "/display-homes/selection-studio",
    meta: indexCUPirXSCIBMeta || {},
    alias: indexCUPirXSCIBMeta?.alias || [],
    redirect: indexCUPirXSCIBMeta?.redirect,
    component: () => import("/app/pages/display-homes/selection-studio/index.vue").then(m => m.default || m)
  },
  {
    name: indexSfuuiyCw42Meta?.name ?? "double-storey-house-plans",
    path: indexSfuuiyCw42Meta?.path ?? "/double-storey-house-plans",
    meta: indexSfuuiyCw42Meta || {},
    alias: indexSfuuiyCw42Meta?.alias || [],
    redirect: indexSfuuiyCw42Meta?.redirect,
    component: () => import("/app/pages/double-storey-house-plans/index.vue").then(m => m.default || m)
  },
  {
    name: indexK7GFr9nQT9Meta?.name ?? "duplex-houses",
    path: indexK7GFr9nQT9Meta?.path ?? "/duplex-houses",
    meta: indexK7GFr9nQT9Meta || {},
    alias: indexK7GFr9nQT9Meta?.alias || [],
    redirect: indexK7GFr9nQT9Meta?.redirect,
    component: () => import("/app/pages/duplex-houses/index.vue").then(m => m.default || m)
  },
  {
    name: index4pvaAot8x4Meta?.name ?? "explore-the-range",
    path: index4pvaAot8x4Meta?.path ?? "/explore-the-range",
    meta: index4pvaAot8x4Meta || {},
    alias: index4pvaAot8x4Meta?.alias || [],
    redirect: index4pvaAot8x4Meta?.redirect,
    component: () => import("/app/pages/explore-the-range/index.vue").then(m => m.default || m)
  },
  {
    name: indexR9dFZtSlxZMeta?.name ?? "home-designs-slug",
    path: indexR9dFZtSlxZMeta?.path ?? "/home-designs/:slug()",
    meta: indexR9dFZtSlxZMeta || {},
    alias: indexR9dFZtSlxZMeta?.alias || [],
    redirect: indexR9dFZtSlxZMeta?.redirect,
    component: () => import("/app/pages/home-designs/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index6zo5OUWAvYMeta?.name ?? "house-and-land-packages",
    path: index6zo5OUWAvYMeta?.path ?? "/house-and-land-packages",
    meta: index6zo5OUWAvYMeta || {},
    alias: index6zo5OUWAvYMeta?.alias || [],
    redirect: index6zo5OUWAvYMeta?.redirect,
    component: () => import("/app/pages/house-and-land-packages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexpbK3uoJMnjMeta?.name ?? "james-hardie",
    path: indexpbK3uoJMnjMeta?.path ?? "/james-hardie",
    meta: indexpbK3uoJMnjMeta || {},
    alias: indexpbK3uoJMnjMeta?.alias || [],
    redirect: indexpbK3uoJMnjMeta?.redirect,
    component: () => import("/app/pages/james-hardie/index.vue").then(m => m.default || m)
  },
  {
    name: indexLkUZTZqQtlMeta?.name ?? "jh-styles-slug",
    path: indexLkUZTZqQtlMeta?.path ?? "/jh-styles/:slug()",
    meta: indexLkUZTZqQtlMeta || {},
    alias: indexLkUZTZqQtlMeta?.alias || [],
    redirect: indexLkUZTZqQtlMeta?.redirect,
    component: () => import("/app/pages/jh-styles/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexlUxjb7K1eyMeta?.name ?? "our-collections-august",
    path: indexlUxjb7K1eyMeta?.path ?? "/our-collections/august",
    meta: indexlUxjb7K1eyMeta || {},
    alias: indexlUxjb7K1eyMeta?.alias || [],
    redirect: indexlUxjb7K1eyMeta?.redirect,
    component: () => import("/app/pages/our-collections/august/index.vue").then(m => m.default || m)
  },
  {
    name: indexjD4cc5Ux45Meta?.name ?? "our-collections",
    path: indexjD4cc5Ux45Meta?.path ?? "/our-collections",
    meta: indexjD4cc5Ux45Meta || {},
    alias: indexjD4cc5Ux45Meta?.alias || [],
    redirect: indexjD4cc5Ux45Meta?.redirect,
    component: () => import("/app/pages/our-collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexziOWPWspxsMeta?.name ?? "our-collections-lifestyle",
    path: indexziOWPWspxsMeta?.path ?? "/our-collections/lifestyle",
    meta: indexziOWPWspxsMeta || {},
    alias: indexziOWPWspxsMeta?.alias || [],
    redirect: indexziOWPWspxsMeta?.redirect,
    component: () => import("/app/pages/our-collections/lifestyle/index.vue").then(m => m.default || m)
  },
  {
    name: indexQmZvqx2nBGMeta?.name ?? "our-collections-mayfair",
    path: indexQmZvqx2nBGMeta?.path ?? "/our-collections/mayfair",
    meta: indexQmZvqx2nBGMeta || {},
    alias: indexQmZvqx2nBGMeta?.alias || [],
    redirect: indexQmZvqx2nBGMeta?.redirect,
    component: () => import("/app/pages/our-collections/mayfair/index.vue").then(m => m.default || m)
  },
  {
    name: indexpnR0nwMn4QMeta?.name ?? "our-homes-double-storey",
    path: indexpnR0nwMn4QMeta?.path ?? "/our-homes/double-storey",
    meta: indexpnR0nwMn4QMeta || {},
    alias: indexpnR0nwMn4QMeta?.alias || [],
    redirect: indexpnR0nwMn4QMeta?.redirect,
    component: () => import("/app/pages/our-homes/double-storey/index.vue").then(m => m.default || m)
  },
  {
    name: indexOEsJe3fczSMeta?.name ?? "our-homes-dual-occupancy-homes",
    path: indexOEsJe3fczSMeta?.path ?? "/our-homes/dual-occupancy-homes",
    meta: indexOEsJe3fczSMeta || {},
    alias: indexOEsJe3fczSMeta?.alias || [],
    redirect: indexOEsJe3fczSMeta?.redirect,
    component: () => import("/app/pages/our-homes/dual-occupancy-homes/index.vue").then(m => m.default || m)
  },
  {
    name: indexqaE5c7S9SpMeta?.name ?? "our-homes",
    path: indexqaE5c7S9SpMeta?.path ?? "/our-homes",
    meta: indexqaE5c7S9SpMeta || {},
    alias: indexqaE5c7S9SpMeta?.alias || [],
    redirect: indexqaE5c7S9SpMeta?.redirect,
    component: () => import("/app/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: indexzB1UDSlaRrMeta?.name ?? "our-homes-knockdown-rebuild",
    path: indexzB1UDSlaRrMeta?.path ?? "/our-homes/knockdown-rebuild",
    meta: indexzB1UDSlaRrMeta || {},
    alias: indexzB1UDSlaRrMeta?.alias || [],
    redirect: indexzB1UDSlaRrMeta?.redirect,
    component: () => import("/app/pages/our-homes/knockdown-rebuild/index.vue").then(m => m.default || m)
  },
  {
    name: indexwfFcOFbHmJMeta?.name ?? "our-homes-multi-dwelling-houses",
    path: indexwfFcOFbHmJMeta?.path ?? "/our-homes/multi-dwelling-houses",
    meta: indexwfFcOFbHmJMeta || {},
    alias: indexwfFcOFbHmJMeta?.alias || [],
    redirect: indexwfFcOFbHmJMeta?.redirect,
    component: () => import("/app/pages/our-homes/multi-dwelling-houses/index.vue").then(m => m.default || m)
  },
  {
    name: index0LpzNszo5FMeta?.name ?? "our-homes-single-storey",
    path: index0LpzNszo5FMeta?.path ?? "/our-homes/single-storey",
    meta: index0LpzNszo5FMeta || {},
    alias: index0LpzNszo5FMeta?.alias || [],
    redirect: index0LpzNszo5FMeta?.redirect,
    component: () => import("/app/pages/our-homes/single-storey/index.vue").then(m => m.default || m)
  },
  {
    name: indexLG3Wtz8U6fMeta?.name ?? "partners",
    path: indexLG3Wtz8U6fMeta?.path ?? "/partners",
    meta: indexLG3Wtz8U6fMeta || {},
    alias: indexLG3Wtz8U6fMeta?.alias || [],
    redirect: indexLG3Wtz8U6fMeta?.redirect,
    component: () => import("/app/pages/partners/index.vue").then(m => m.default || m)
  },
  {
    name: indext2mji5fLq5Meta?.name ?? "privacy-policy",
    path: indext2mji5fLq5Meta?.path ?? "/privacy-policy",
    meta: indext2mji5fLq5Meta || {},
    alias: indext2mji5fLq5Meta?.alias || [],
    redirect: indext2mji5fLq5Meta?.redirect,
    component: () => import("/app/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: index2ewsxcR5WvMeta?.name ?? "single-storey-house-plans",
    path: index2ewsxcR5WvMeta?.path ?? "/single-storey-house-plans",
    meta: index2ewsxcR5WvMeta || {},
    alias: index2ewsxcR5WvMeta?.alias || [],
    redirect: index2ewsxcR5WvMeta?.redirect,
    component: () => import("/app/pages/single-storey-house-plans/index.vue").then(m => m.default || m)
  },
  {
    name: indexBOCZxyK5PsMeta?.name ?? "where-we-build",
    path: indexBOCZxyK5PsMeta?.path ?? "/where-we-build",
    meta: indexBOCZxyK5PsMeta || {},
    alias: indexBOCZxyK5PsMeta?.alias || [],
    redirect: indexBOCZxyK5PsMeta?.redirect,
    component: () => import("/app/pages/where-we-build/index.vue").then(m => m.default || m)
  }
]
export default defineNuxtPlugin(() => {
    if (!import.meta.client) {
        return;
    }

    const forms = document.querySelectorAll('.gravity-form');

    forms.forEach(form => {
        form.addEventListener('submit', async (e) => {
            e.preventDefault();

            const btn = form.querySelector('[type="submit"]');
            const div = form.querySelector('#submit-message');

            btn.setAttribute('disabled', 'disabled');

            const response = await fetch(form?.action, {body: new FormData(form), method: 'POST'});
            const json = await response?.json();

            if (response?.ok && alert) {
                const message = json?.confirmation_message || '';

                div.innerHTML = message.toString();
                div.classList.add('text-success');
                div.classList.remove('text-danger');
                div.classList.remove('d-none');
            } else {
                const validations = [];
                const messages = json?.validation_messages || {};

                Object.keys(messages).forEach((k) => validations.push(messages[k]));
                div.innerHTML = validations.join('<br/>');
                div.classList.add('text-danger');
                div.classList.remove('text-success');
                div.classList.remove('d-none');
            }

            btn.removeAttribute('disabled');
        });
    })
});

export default defineNuxtPlugin(() => {
    const navbar = document.getElementById('navbar');
    const navbarSticky = document.getElementById('navbar-sticky');

    if (!import.meta.client && !navbar && !navbarSticky) {
        return;
    }

    let currentScrollY = 0;
    let rendering = false;

    const btn = document.getElementById('btn-scroll');
    const container = document.getElementById('page-container');
    const jhContainer = document.getElementById('jh-header-container');
    const jhScrollHeaderBtn = document.getElementById('jh-scroll-header');
    const jhScrollSectionBtn = document.getElementById('jh-scroll-section');
    const titleExpand = document.querySelectorAll('.title-expand');
    const menu = document.querySelector('.navbar-nav .navbar-dropdown:first-child');

    const render = () => {

        if (jhContainer !== undefined && jhContainer !== null) {
            if (currentScrollY < ((jhContainer.offsetTop - navbar.offsetHeight))) {
                navbar?.classList?.remove('bg-dark');
            } else if (!navbar.classList.contains('bg-dark')) {
                navbar?.classList?.add('bg-dark');
            }
        } else {
            if (currentScrollY < (container.offsetTop - navbar.offsetHeight)) {
                navbar?.classList?.remove('bg-dark');
            } else if (!navbar.classList.contains('bg-dark')) {
                navbar?.classList?.add('bg-dark');
            }
        }
        rendering = false;
    }

    const onScroll = () => {
        currentScrollY = window.scrollY;
        if (rendering) {
            return;
        }

        requestAnimationFrame(render);
    };

    const doScroll = () => {
        let offsetHeight = 0;
        if (navbar) {
            offsetHeight = navbar.offsetHeight;
        } else if (navbarSticky) {
            offsetHeight = navbarSticky.offsetHeight;
        }

        window.scrollTo({
            top: (container.offsetTop - offsetHeight),
            behavior: 'smooth',
        })
    };

    const doScrollJh = (event) => {
        var currentBtn = event.currentTarget;
        const nextContainer = document.getElementById(currentBtn?.dataset?.nextId);

        if (nextContainer) {
            let offsetHeight = 0;
            if (navbar) {
                offsetHeight = navbar.offsetHeight;
            } else if (navbarSticky) {
                offsetHeight = navbarSticky.offsetHeight;
            }
    
            window.scrollTo({
                top: (nextContainer.offsetTop - offsetHeight),
                behavior: 'smooth',
            });
        }
    }

    const toggleFooter = (item) => {
        const parentNode = item.closest(".footer-title").parentNode;
        const nav = parentNode.querySelector(".nav");

        if (nav?.classList?.contains('show')) {
            nav?.classList?.remove('show');
        } else {
            nav?.classList?.add('show');
        }
    }

    const expand1stDropdownMenu = () => {
        if (menu) {
            const arrow = menu.querySelector('a.nav-link.dropdown-toggle.w-25');
            const dropdown = menu.querySelector('ul.dropdown-menu');
    
            if (window.innerWidth < 1200 && menu) {
                dropdown?.classList?.add('show');
                arrow?.classList?.add('show');
                arrow?.setAttribute('aria-expanded', 'true');
            } else {
                dropdown?.classList?.remove('show');
                arrow?.classList?.remove('show');
                arrow?.setAttribute('aria-expanded', 'false');
            }
        }
    }

    titleExpand.forEach(item => item.addEventListener('click', () => toggleFooter(item)));

    if (navbar || navbarSticky) {
        window.addEventListener('resize', expand1stDropdownMenu);
        expand1stDropdownMenu();
    }

    if (navbar) {
        window.addEventListener('scroll', onScroll);
        onScroll();
    }

    if (btn) {
        btn.addEventListener('click', doScroll);
    }

    if (jhScrollHeaderBtn) {
        jhScrollHeaderBtn.addEventListener('click', doScrollJh);
    }

    if (jhScrollSectionBtn) {
        jhScrollSectionBtn.addEventListener('click', doScrollJh);
    }
});

export default defineNuxtPlugin(() => {
    const input = document.querySelector('#postcode');
    const form = document.querySelector('#check-postcode-form');
    const divFound = document.querySelector('#postcode-found');
    const divNotFound = document.querySelector('#postcode-not-found');
    const spinner = document.querySelector('#postcode-spinner');

    if (!process.client || !form) {
        return;
    }

    const showMessage = (found, notFound) => {

        divFound?.classList.add("d-none");
        divNotFound?.classList.add("d-none");
        spinner?.classList.remove('d-none');

        setTimeout(() => {

            if (found) {
                divFound?.classList.remove("d-none");
            } else if (!divFound.classList.contains('d-none')) {
                divFound.classList.add("d-none");
            }
    
            if (notFound) {
                divNotFound?.classList.remove("d-none");
            } else if (!divNotFound.classList.contains('d-none')) {
                divNotFound.classList.add("d-none");
            }

            spinner?.classList.add('d-none');

        }, 500);
    }

    form?.addEventListener('submit', (e) => {
        e.preventDefault();

        const postcode = input?.value?.toString() || "";
        const codes = form?.dataset?.postcodes;

        if (!postcode) {
            showMessage(false, false);
        } else if (codes.includes(postcode)) {
            showMessage(true, false);
        } else {
            showMessage(false, true);
        }
    });

    input?.addEventListener('change', () => {
        const postcode = input?.value?.toString() || "";
        if (!postcode) {
            showMessage(false, false);
        }
    })
});

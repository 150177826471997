export default defineNuxtPlugin(() => {
    const btnFilters = document.querySelectorAll('.btn-blog-filter');
    const divPosts = document.querySelectorAll('.div-category-filter');

    const displayDiv = category => {
        divPosts.forEach(div => {
            const categories = JSON.parse(div.dataset.categories);
            if (category === "*" || categories.indexOf(category) > -1) {
                div.classList.remove('d-none');
            } else if (!div.classList.contains('d-none')) {
                div.classList.add('d-none');
            }
        });
    };

    const activateBtnFilter = category => {
        btnFilters.forEach(el => {
            el.classList.add('btn-light');
            el.classList.remove('btn-dark');
        });

        const btn = document.querySelector(`button[data-category="${category}"]`);
        btn.classList.add('btn-dark');
        btn.classList.remove('btn-light');
    };

    btnFilters.forEach(btn => {
        btn.addEventListener('click', evt => {
            evt.preventDefault();
            displayDiv(evt.target.dataset.category || "*");
            activateBtnFilter(evt.target.dataset.category);
        })
    });
});

import {useRoute} from "vue-router";

export default defineNuxtPlugin(() => {
    const homeDesignsDiv = document.querySelector('.home-designs');

    if (!import.meta.client || !homeDesignsDiv) {
        return;
    }

    const route = useRoute();

    const houseDesignLinks = document.querySelectorAll('.house-design-link');
    const blockWidthInput = document.querySelector('#filter-block-width');
    const houseSizeInput = document.querySelector('#filter-house-size');
    const storeysInput = document.querySelector('#filter-storeys');
    const bathInput = document.querySelector('#filter-bath');
    const carInput = document.querySelector('#filter-car');
    const bedInput = document.querySelector('#filter-bed');

    const onClick = e => {
        e.preventDefault();
        const {houseId, sequence} = e.target.dataset;

        document.querySelectorAll(`.house-${houseId}-img`).forEach(img => img.classList.add("d-none"));
        document.querySelector(`#house-${houseId}-img-${sequence}`).classList.remove("d-none");

        document.querySelectorAll(`.house-${houseId}-desc`).forEach(img => img.classList.add("d-none"));
        document.querySelector(`#house-${houseId}-desc-${sequence}`).classList.remove("d-none");

        houseDesignLinks.forEach(link => link.classList.remove("bg-dark-subtle"));
        e.target.classList.add("bg-dark-subtle");
    };

    const filterHouses = () => {
        const filterBlock = parseFloat(blockWidthInput.value);
        const filterSize = parseFloat(houseSizeInput.value);
        const filterStorey = storeysInput.value.toString();
        const filterBath = bathInput.value.toString();
        const filterCar = carInput.value.toString();
        const filterBed = bedInput.value.toString();

        document.querySelectorAll('.house-item').forEach(item => {
            const sizes = JSON.parse(item.dataset.sizes);
            const blocks = JSON.parse(item.dataset.blocks);
            const carSpaces = JSON.parse(item.dataset.carSpaces);
            const bedrooms = JSON.parse(item.dataset.bedrooms);
            const storeys = JSON.parse(item.dataset.storeys);
            const bathrooms = JSON.parse(item.dataset.bathrooms);

            if ((blocks.filter(v => parseFloat(v) <= filterBlock).length > 0)
                && (sizes.filter(v => parseFloat(v) <= filterSize).length > 0)
                && (filterCar === "-" || carSpaces.includes(filterCar))
                && (filterBed === "-" || bedrooms.includes(filterBed))
                && (filterBath === "-" || bathrooms.includes(filterBath))
                && (filterStorey === "-" || storeys.includes(filterStorey))
            ) {
                item.classList.remove("d-none");
            } else if (!item.classList.contains("d-none")) {
                item.classList.add("d-none");
            }
        });

        const houseItems = document.querySelectorAll('.house-item:not(.d-none)');

        if (houseItems.length == 0) {
            document.querySelector('#our-homes-no-results').classList.remove('d-none');
        } else {
            document.querySelector('#our-homes-no-results').classList.add('d-none');
        }
    };

    const updateInputRange = (e) => document
        .getElementById(e.target.dataset.target)
        .innerText = e.target.value;

    houseDesignLinks.forEach(link => link.addEventListener('click', onClick));
    blockWidthInput.addEventListener('input', updateInputRange);
    houseSizeInput.addEventListener('input', updateInputRange);
    blockWidthInput.addEventListener('change', filterHouses);
    houseSizeInput.addEventListener('change', filterHouses);
    storeysInput.addEventListener('change', filterHouses);
    bathInput.addEventListener('change', filterHouses);
    carInput.addEventListener('change', filterHouses);
    bedInput.addEventListener('change', filterHouses);

    if (route.query.hasOwnProperty('width')) {
        blockWidthInput.value = parseInt(route.query.width);
        filterHouses();
    }
});

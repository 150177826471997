
import $ from 'jquery';
export default defineNuxtPlugin(() => {

    let sidebar = document.getElementById("sidebar-steps-nav");
    let stepCover = document.getElementById("step-cover-img");
    let sidebarWrapper = document.getElementById("sidebar-wrapper");
    let stopPointDiv = document.getElementById("step-img-stop");
    let navbar = document.getElementById('navbar');
    let currentScrollY = 0;
    let rendering = false;
    let currentIndex = 0;

    const processContainer = document.getElementById('process-div-wrapper');
    const imageContainer = document.getElementById('sidebar-steps-nav');
    const stepContainer = document.getElementById('step-wrapper-id');
    const images = stepContainer?.querySelectorAll('.step-img');
    const nextSection = document.getElementById('nextDiv');
    let isFinalImage = false;

    let isAtStart = true;
    let isAtEnd = false;
    let scrollBuffer = 0; // Buffer to manage smooth transition to normal scrolling

    const render = () => {

        if (stopPointDiv) {

            var stopPoint = stopPointDiv.getBoundingClientRect().top + window.scrollY - sidebar.offsetHeight -150;

            if (window.scrollY >= stopPoint) {
                sidebar.style.top = stopPoint - 50 + "px";

                sidebar?.classList?.remove('fixed-sidebar');
                sidebar?.classList?.add('stop-sidebar');

                stepCover.style.top = stopPoint - 50 + "px";

                stepCover?.classList?.remove('fixed-sidebar');
                stepCover?.classList?.add('stop-sidebar');

                var currentActive = document.querySelector('.step-img.active');
                currentActive?.classList?.remove('fixed-sidebar');
            }

            else if (currentScrollY < (sidebarWrapper.offsetTop - navbar.offsetHeight)) {
                sidebar?.classList?.remove('fixed-sidebar');
                sidebar?.classList?.remove('stop-sidebar');

                stepCover?.classList?.remove('fixed-sidebar');
                stepCover?.classList?.remove('stop-sidebar');

            } else if (!sidebar.classList.contains('fixed-sidebar')) {
                sidebar?.classList?.add('fixed-sidebar');
                sidebar?.classList?.remove('stop-sidebar');
                sidebar.style.top = 100 + "px";
                
                if (stepCover.classList.contains('active')) {
                    stepCover?.classList?.add('fixed-sidebar');
                    // stepCover.style.top = 100 + "px";
                } else if (currentIndex == (images.length - 1)) {

                    if (images[images.length - 1]) {

                        const lastImg = images[images.length - 1];
                        lastImg?.classList?.add('fixed-sidebar');
                    }
                }
                stepCover?.classList?.remove('stop-sidebar');
            }
            rendering = false;
        }  
    }

    const onScroll = () => {
        currentScrollY = window.scrollY;
        if (rendering) {
            return;
        }

        requestAnimationFrame(render);
    };

    window.addEventListener('scroll', onScroll);
    onScroll();

    var anchors = document.getElementsByClassName('steps-link');
    for(var i = 0; i < anchors.length; i++) {
        var anchor = anchors[i];

        anchor.addEventListener('click', function(e) {
            e.preventDefault();
            const targetId = this.getAttribute('data-title');
            const targetElement = document.getElementById('step-img-'+targetId);

            targetElement.classList.add('stuck');
            if (targetElement) {
                targetElement.scrollIntoView({
                    behavior: "smooth", // Smooth scroll animation
                    block: "start", // Align the section to the top of the viewport
                });
            }
        });
    }

    // slider for modal
    $('.modal-link').on('click', function () {
        const index = this.getAttribute('data-index');
        const timeout = setTimeout(() => {
            const $slider = $('#modal-slider-'+index);

            if ($slider.hasClass('slider')) {
                $slider.not('.slick-initialized').slick({
                  slidesToScroll: 1,
                  slidesToShow: 1,
                  initialSlide: 1,
                  arrows: true,
                  responsive: [
                    {
                      breakpoint: 1199.98,
                      settings: {
                        slidesToShow: 1,
                      }
                    },
                    {
                      breakpoint: 991.98,
                      settings: {
                        slidesToShow: 1,
                      }
                    },
                    {
                      breakpoint: 575.98,
                      settings: {
                        slidesToShow: 1,
                      }
                    },
                  ]
                });

                $slider.show();
                $('#modal-loader-'+index).hide();
            }
            clearTimeout(timeout);
        }, 500);
    });
});

export default defineNuxtPlugin(() => {
  const buttons = document.querySelectorAll('button[data-scroll-to]');

  if (buttons.length === 0) {
    return;
  }

  const onClickEventListener = (btn) => {
    const next = document.getElementById(btn.dataset.scrollTo);
    if (next) {
      window.scrollBy({
        top: next.getBoundingClientRect().top - 150,
        behavior: 'smooth',
      });
    }
  };

  buttons.forEach(btn => {
    console.log({btn});
    btn.addEventListener('click', () => onClickEventListener(btn))
  });
});

export default defineNuxtPlugin(() => {
    if (!import.meta.client) {
        return;
    }

    const divs = document.querySelectorAll('.animate-onview');
    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.2,
    };

    const callback = (entries) => {
        entries.forEach(entry => {
            if (!entry.isIntersecting) {
                return;
            }

            const div = entry.target;
            if (!div.classList.contains('animated')) {
                return;
            }

            const animation = div.dataset.animation;
            if (!div.classList.contains(animation)) {
                div.classList.add(animation);
            }
        });
    };

    const observer = new IntersectionObserver(callback, options);

    divs.forEach(div => observer.observe(div));
});

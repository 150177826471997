import gtm from "../data/gtm.json";

export default defineNuxtPlugin(() => {

    let scripts = [];
    let noscripts = [];

    if (gtm?.googleTag?.scripts) {
        gtm.googleTag.scripts.forEach(item => {

            let obj = {};
            if (item.asChildren[0] == 'true') {
                obj = {
                    children: item.source,
                    tagPosition: item.position
                }
            } else {
                const cleanedString = item.source
                .trim()
                .replace(/\r\n/g, "")
                .replace(/,\s*}/g, "}");
                try {
                    const jsonObject = JSON.parse(cleanedString);
                    obj = jsonObject;
                } catch (error) {
                    console.error("JSON Parsing Error:", error.message);
                }
            }   

            if (item.type === 'script') {
                scripts.push(obj);
            } else {
                noscripts.push(obj);
            }
        });
    }

    useHead({
        script: scripts,
        noscript: noscripts
    })

});
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library, config} from "@fortawesome/fontawesome-svg-core";
import {faStar, faChevronDown, faMinus, faPlus, faChevronLeft, faChevronRight, faBars} from "@fortawesome/free-solid-svg-icons";
import {faCircleCheck} from "@fortawesome/free-regular-svg-icons";

library.add(faStar, faChevronDown, faMinus, faPlus, faCircleCheck, faChevronLeft, faChevronRight, faBars);
config.autoAddCss = false;

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
})

import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_RdadCF4ArJ from "/app/node_modules/nuxt-aos/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import fontawesome_klhsrycjcK from "/app/plugins/fontawesome.js";
import bootstrap_S5A03TdDOt from "/app/plugins/bootstrap.js";
import navbar_zQ7OTAB239 from "/app/plugins/navbar.js";
import blog_8ObqQ84Xwv from "/app/plugins/blog.js";
import form_b3sY60EkAs from "/app/plugins/form.js";
import where_we_build_13NH9FzsMG from "/app/plugins/where-we-build.js";
import home_designs_AplcEFSUBM from "/app/plugins/home-designs.js";
import hover_item_blur_sPvlHgfFHx from "/app/plugins/hover-item-blur.js";
import animations_AQWVmpP1E8 from "/app/plugins/animations.js";
import carousel_1nvIEkDNzf from "/app/plugins/carousel.js";
import custom_process_ZtENyJyOCH from "/app/plugins/custom-process.js";
import tag_scripts_YfoKNMAKj7 from "/app/plugins/tag-scripts.js";
import discover_quiz_m4VZVP1kyB from "/app/plugins/discover-quiz.js";
import scroll_next_id_client_xcKF4e0SM0 from "/app/plugins/scroll-next-id.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_RdadCF4ArJ,
  chunk_reload_client_UciE0i6zes,
  fontawesome_klhsrycjcK,
  bootstrap_S5A03TdDOt,
  navbar_zQ7OTAB239,
  blog_8ObqQ84Xwv,
  form_b3sY60EkAs,
  where_we_build_13NH9FzsMG,
  home_designs_AplcEFSUBM,
  hover_item_blur_sPvlHgfFHx,
  animations_AQWVmpP1E8,
  carousel_1nvIEkDNzf,
  custom_process_ZtENyJyOCH,
  tag_scripts_YfoKNMAKj7,
  discover_quiz_m4VZVP1kyB,
  scroll_next_id_client_xcKF4e0SM0
]
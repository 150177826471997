import $ from 'jquery';
export default defineNuxtPlugin(() => {

  var anchors = document.getElementsByClassName('explore-style-link');
  for(var i = 0; i < anchors.length; i++) {
      var anchor = anchors[i];

      anchor.addEventListener('click', function(e) {
          e.preventDefault();
          const targetId = this.getAttribute('data-slug');
          const targetElement = document.getElementById(targetId);
          const offset = 50; // Offset to account for the fixed navigation

          window.scrollTo({
              top: targetElement.offsetTop - offset,
              behavior: 'smooth'
          });
      });
  }


});
